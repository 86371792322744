import { memo, useMemo } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Games } from '../constants/games'

function GamePage() {
    const { gameId } = useParams()
    const game = useMemo(() => Games.find(({ id }) => id === gameId), [gameId])

    if (!game) {
        return <Navigate to='/' />;
    }

    return (
        <div className='page game-page flex-column'>
            <div className='back-header'>
                <Link to='/' replace>
                    <img src='/assets/back.svg' alt='back' />
                </Link>
            </div>
            <iframe src={game.url} title={game.name} />
        </div>
    )
}

export default memo(GamePage)