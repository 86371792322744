import { memo } from 'react'
import { Games } from '../constants/games'
import { Link } from 'react-router-dom'

function HomePage() {
    return (
        <div className='page flex-center'>
            <div className='home flex-column'>
                <div className='header flex-center flex-column'>
                    <img src='/assets/logo.svg' alt='logo' />
                    <h2>Play slots for free</h2>
                </div>
                <div className='games'>
                    {Games.map((game) => (
                        <Link key={game.id} to={`/game/${game.id}`}>
                            <img src={`/assets/${game.image}`} alt={game.name} />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default memo(HomePage)