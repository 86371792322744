export const Games = [
    {
        id: 'cashMoney',
        name: 'Cash Money',
        image: 'cash_money.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/CashMoney/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'doubleJackpotGems',
        name: 'Double Jackpot Gems',
        image: 'double_jackpot.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/DoubleJackpotGems/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'shamrock',
        name: 'Shamrock',
        image: 'shamrock.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/Shamrock/?platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'doubleJackpotBullseye',
        name: 'Double Jackpot Bullseye',
        image: 'bullseye.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/DoubleJackpotBullseye/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'tripleThreat',
        name: 'Triple Threat',
        image: 'tripple_threat.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/TripleThreat/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'totalMeltdown',
        name: 'Total Meltdown',
        image: 'total_meltdown.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/TotalMeltdown/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'kingmaker',
        name: 'Kingmaker',
        image: 'kingmaker.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/Kingmaker/?platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
    {
        id: 'blackDiamond',
        name: 'Black Diamond',
        image: 'black_diamond.png',
        url: 'https://awsrgs.everi-interactive.com/gateway/gamehost/BlackDiamond/?fresh=1599680941481&userId=&platformId=everi-demo&operatorId=everi-social-demo&jurisdictionId=everi',
    },
]