/* eslint-disable import/order */
// We disable eslint import order formatting, to avoid legacy circular dependency error

import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
  } from 'react-router-dom'
import Home from './pages/Home'
import Game from './pages/Game'

  const Routes = (
    <Route path='/'>
        <Route index Component={Home} />
        <Route path='game/:gameId' Component={Game} />
        <Route path="*" element={<Navigate to='/' />} />
    </Route>
  )

  export default createBrowserRouter(createRoutesFromElements(Routes))
